@tailwind base;
@tailwind components;
@tailwind utilities;

$blue: #032859;
$cyan: #3EBFF7;
$light-cyan: #e2fffc;

* {
  font-family: 'Poppins';
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: $light-cyan;  
}

.bg-light-cyan {
  background-color: $light-cyan;
}

.bg-blue {
  background-color: $blue;
}

.bg-cyan {
  background-color: $cyan;
}

.color-light-cyan {
  color: $light-cyan;
}

.color-blue {
  color: $blue;
}

.color-cyan {
  color: $cyan;
}

.medical-card {
  transition: transform 500ms ease-in-out 25ms;
  
  &:hover {
    transform: translate(0px, -20px) scale(1.1);
  }
}